<template>
  <v-container>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="filter.Nome"
            label="Buscar (nome)"
            single-line
            hide-details
            class="mr-5"
            @keyup.enter="load"
          />
          <v-text-field
            v-model="filter.cpf"
            label="Buscar (cpf)"
            single-line
            hide-details
            class="mr-5"
            @keyup.enter="load"
          />
          <v-text-field
            v-model="filter.codigoStyllus"
            label="Buscar (código styllus)"
            single-line
            hide-details
            type="number"
            @keyup.enter="load"
          />
          <v-spacer />
          <v-btn
            color="primary"
            @click="load"
          >
            Buscar
          </v-btn>
        </v-card-title>
      </v-card>
    </v-col>
    <v-data-table
      :headers="headers"
      :items="cupomSolicitacoes"
      :options.sync="options"
      :server-items-length="total"
      class="elevation-1"
      :footer-props="footer_props"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Listagem de Solicitações</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          text
          small
          @click.stop="linkCupom(item)"
        >
          <v-icon
            class="primary--text"
            small
          >
            mdi-link
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showLinkCupom"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5 px-2">
          Código do Cupom
        </v-card-title>
        <v-card-text class="pb-0 px-2">
          <v-text-field
            v-model="texto"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="onClose"
          >
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            @click="onSubmit"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      page: 1,
      perPage: 10,
      headers: [
        { text: 'Código Styllus', value: 'codigoStyllus' },
        { text: 'Nome', value: 'nome' },
        { text: 'CPF', value: 'cpf' },
        { text: 'E-mail', value: 'email' },
        { text: '', value: 'actions', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        Term: '',
      },
      cupomSolicitacoes: [],
      showLinkCupom: false,
      id: '',
      codigoStyllus: '',
      texto: '',
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        if (this.options.sortBy[0]) {
          this.filter.orderBy = this.options.sortBy[0]
          this.filter.sortDesc = this.options.sortDesc[0]
        }
        const query = JSON.stringify(this.filter)
        this.$http.get('/cupom', { params: { query } })
          .then(resp => {
            this.cupomSolicitacoes = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      linkCupom (item) {
        this.id = item.id
        this.codigoStyllus = item.codigoStyllus
        this.showLinkCupom = true
      },
      onClose () {
        this.id = ''
        this.codigoStyllus = ''
        this.texto = ''
        this.showLinkCupom = false
      },
      onSubmit () {
        this.showLinkCupom = false
        const cupom = {
          id: this.id,
          codigoStyllus: this.codigoStyllus,
          texto: this.texto,
        }
        this.$store.dispatch('linkCupom', cupom)
          .then(() => {
            this.$toast.success('Operação realizada com sucesso')
            this.load()
          })
      },
    },
  }
</script>
